let mainPath = "/reporting";
let viewFolder = "Reporting";

module.exports = [
    {
        path: mainPath,
        name: 'reports',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Index.vue'),
    },
    {
        path: mainPath+'/simplifiedordersales',
        name: 'Simplified Order Sales',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/SimplifiedOrderSales.vue'),
    },
    {
        path: mainPath+'/ordersales',
        name: 'Order Sales by Customer',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/OrderSales.vue'),
    },
    {
        path: mainPath+'/ordersalesbycsr',
        name: 'Order Sales by CSR',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/OrderSalesByCSR.vue'),
    },
    {
        path: mainPath+'/paymentHistory',
        name: 'Payment History',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentHistory.vue'),
    },
    {
        path: mainPath+'/paymentHistoryWithOrder',
        name: 'Payment History With Orders',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/PaymentHistoryWithOrder.vue'),
    },
    {
        path: mainPath+'/commissions',
        name: 'Commissions',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/Commissions.vue'),
    },
    {
        path: mainPath+'/vatsales',
        name: 'VAT Sales',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/VatSales.vue'),
    },
    {
        path: mainPath+'/registerreport',
        name: 'Register Report',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/RegisterReport.vue'),
    },
    {
        path: mainPath+'/customersOutstandingOnAccount',
        name: 'Customers With Outstanding Balances',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/CustomerOutstandingOnAccount.vue'),
    },
    // {
    //     path: mainPath+'/customersOutstandingAged',
    //     name: 'Customer Outstanding Aged',
    //     component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/CustomerOutstandingAged.vue'),
    // },
    {
        path: mainPath+'/quarterlySales',
        name: 'Quarterly Sales',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/QuarterlySales.vue'),
    },
    {
        path: mainPath+'/:customReport',
        name: 'CustomReport',
        component: () => import('../views/'+viewFolder+'/CustomReport.vue')
    }
]