import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad';
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import DialogSnack from './components/dialogSnack.vue';
import JsonExcel from "vue-json-excel";
import VueExcelXlsx from "vue-excel-xlsx";


Vue.component('dialog-snack', DialogSnack);
Vue.component("json-excel", JsonExcel);
Vue.use(VueExcelXlsx);


axios.defaults.withCredentials = true;
axios.interceptors.response.use(undefined, async function axiosRetryInterceptor(err){
  if(err && err.response && err.response.status == 401){
      console.log("ssssss",err)
      localStorage.removeItem('id')
      await router.push({path: '/login'})
      router.go()
  }
  if(err && err.response && err.response.status == 500){
    console.log("LICENSE ERROR DETECTED")
    localStorage.removeItem('id')
    await router.push({path: '/login'})
    router.go()
}
})

Vue.config.productionTip = false
Vue.use(VueSignaturePad)

Vue.use(VueCodemirror)


new Vue({
  router,
  store,
  vuetify,
  VueSignaturePad,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App)
}).$mount('#app')
