export default {
  validateType(val){
    console.log("ScanSystem: ", val)
    if(!val) return false
    let s = val.replace(/\\\/\/\\=/,'').split('-')[0]
    let types = ["VSID", "INV", "DN", "PO", "JT", "PL", "CN"]
    if(types.filter(x=>x===s)[0]){
      return types.filter(x=>x===s)[0]
    }
    return 'SKU'
  },
  validateBarcode(val){
    console.log("ScanSystem: Validating Barcode ", val)
    if(!(/\\\/\/\\=/.test(val))){
      console.log("ScanSystem: Not a valid Barcode.")
      return false // barcode from a scanner would be of the format \//\=.+
    }
    console.log("ScanSystem: Barcode is Valid.")
    return true 
  }
};
