<template>
  <v-container fluid>
    <v-progress-circular
        indeterminate
        color="green"
        v-if="initialLoader"
        style="margin-left: 10px;"
    ></v-progress-circular>
    <v-row style="" v-else>
      <v-col>
        <h1>Welcome {{lookupUsername(getId)}}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="info" small @click="$router.push({path: '/products/serial/Search'})" >Search {{serialNaming.plural}} </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="getGlobalValue('registerModuleEnabled')==='true'">
        <v-card outlined>
          <v-card-text>
            <OpenRegister v-if="getCurrentRegister==null"/>
            <CloseRegister v-else/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import OpenRegister from '../views/Register/Open.vue'
import CloseRegister from '../views/Register/Close.vue'
export default {
  name: 'Home',
  components: {
    OpenRegister,
    CloseRegister
  },
  data () {
    return {
      initialLoader: true,
      deliverySchedule: {
        tableHeaders:[
          {text: 'Delivery ID', align: 'start', value: 'id'},
          { text: 'Customer', value: 'Order.Customer.fullName' },
          { text: 'Delivery Date', value: 'dueDate' },
          { text: '# of Items', value: 'numItems' },
          { text: 'Order #', value: 'orderId' },
          { text: 'Status', value: 'status' },
        ],
        data: null,
      },
      orders: {
        tableHeaders:[
          {text: 'Order ID', align: 'start', value: 'id'},
          { text: 'Date Created', value: 'createdAt' },
          { text: 'CSR', value: 'CSR.fullName' },
          { text: 'Customer', value: 'Customer.fullName' },
          { text: 'Status', value: 'status' },
        ],
        data: null
      }
    }
  },
  computed: {
    ...mapGetters(['lookupUsername', 'getEndpoint', 'getId', 'getCurrentRegister', 'getGlobalValue', 'serialNaming'])
  },
  async mounted(){
    try {
      //
    } catch (error) {
      console.log(error)
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    //
  }
}
</script>
