let mainPath = "/superadmin";
let viewFolder = "SuperAdmin";

module.exports = [
    {
        path: mainPath,
        name: 'superadmin',
        component: () => import('../views/'+viewFolder+'/Index.vue'),
       
    },
    {
        path: mainPath+'/documenttypes',
        name: 'Document Types',
        component: () => import('../views/'+viewFolder+'/DocumentTypes.vue'),
       
    },
    {
        path: mainPath+'/transactiontypes',
        name: 'Transaction Types',
        component: () => import('../views/'+viewFolder+'/TransactionTypes.vue'),
       
    },
    {
        path: mainPath+'/permissions',
        name: 'User Types & Permissions',
        component: () => import('../views/'+viewFolder+'/Permissions.vue'),
       
    },
    {
        path: mainPath+'/creditnotetypes',
        name: 'Credit Note Types',
        component: () => import('../views/'+viewFolder+'/CreditNoteTypes.vue'),
       
    },
    {
        path: mainPath+'/paymenttypes',
        name: 'Payment Types',
        component: () => import('../views/'+viewFolder+'/PaymentTypes.vue'),
       
    },
    {
        path: mainPath+'/paymentmethods',
        name: 'Payment Methods',
        component: () => import('../views/'+viewFolder+'/PaymentMethods.vue'),
       
    },
    {
        path: mainPath+'/registers',
        name: 'Registers',
        component: () => import('../views/'+viewFolder+'/Registers.vue'),

    },
    {
        path: mainPath+'/notifications',
        name: 'Notifications',
        component: () => import('../views/'+viewFolder+'/Notifications.vue'),
       
    },
    {
        path: mainPath+'/taxtypes',
        name: 'Tax Types',
        component: () => import('../views/'+viewFolder+'/TaxTypes.vue'),
       
    },
    {
        path: mainPath+'/shippingzones',
        name: 'Shipping Zones',
        component: () => import('../views/'+viewFolder+'/ShippingZones.vue'),
       
    },
    {
        path: mainPath+'/shippingmethods',
        name: 'Shipping Methods',
        component: () => import('../views/'+viewFolder+'/ShippingMethods.vue'),
       
    },
    {
        path: mainPath+'/globals',
        name: 'Globals',
        component: () => import('../views/'+viewFolder+'/Globals.vue'),
       
    },
    {
        path: mainPath+'/dbutility',
        name: 'DB Utility',
        component: () => import('../views/'+viewFolder+'/Sync.vue'),
       
    },
    {
        path: mainPath+'/shopmonkey',
        name: 'Shopmonkey',
        component: () => import('../views/'+viewFolder+'/Shopmonkey.vue'),
       
    },
    {
        path: mainPath+'/adminutilities',
        name: 'Admin Utilities',
        component: () => import('../views/'+viewFolder+'/AdminUtilities.vue'),
       
    },
    {
        path: mainPath+'/deliveryoptions',
        name: 'DeliveryOptions',
        component: () => import('../views/'+viewFolder+'/DeliveryOptions.vue'),
       
    },
    {
        path: mainPath+'/producthistory',
        name: 'Product History',
        component: () => import('../views/'+viewFolder+'/AdminProductHistory.vue'),

    },
    {
        path: mainPath+'/customControllers',
        name: 'Custom Controllers',
        component: () => import('../views/'+viewFolder+'/CustomControllers.vue'),

    },
    {
        path: mainPath+'/dynamicButtonContainers',
        name: 'Dynamic Button Containers',
        component: () => import('../views/'+viewFolder+'/DynamicButtonContainers.vue'),

    },
    {
        path: mainPath+'/hirepurchase',
        name: 'Hire Purchase',
        component: () => import('../views/'+viewFolder+'/HirePurchase.vue'),
       
    },
]